footer {
    @apply _._bg-[#12032F9c];
}
ul,
ol {
    padding-left: 0 !important;
}
.fw-title {
    color: #0093ff;
}
.fw-title::after {
    display: block;
    content: " ";
    padding: 2px;
    background: linear-gradient(45deg, #7a00ff, transparent);
    border-radius: 10px;
    width: 100%;
}
.footer-link {
    padding: 5px;
    font-size: small;
    line-height: normal;
}
