.logo_text {
    font-size: 40px;
    @apply _._text-white;
}
.active-link {
    // @apply _._ring-2 _._ring-purple-500 _._ring-offset-4 _._ring-offset-slate-50 dark:_._ring-offset-slate-900;
    // @apply _._text-[#6721ff] _._border-b _._border-white;
}

.large {
    @apply _._text-white/50;
    font-size: 160px;
    line-height: 125px;
    text-shadow: 0 0 20px #6400ff;
}
.S {
    margin-bottom: -11%;
    margin-left: -13%;
}
.nor-text {
    font-size: 20px;
}
.Backlink {
    color: red;
}
.Studios {
    color: white;
}
// .axa {
//     transform: scale(1) !important;
//     transition: all 0.4s ease !important;
// }
.axa,
.active-link::after {
    display: inline-block;
    text-decoration: none;
    letter-spacing: 2px;
    position: relative;
    transition: all 0.4s ease;
}

.axa::after,
.active-link::after {
    content: "";
    position: absolute;
    height: 2px;
    background-color: #6721ff;
    width: 0;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
    transition: 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55) all;
}
// .axa:hover {
//     transform: scale(1.2) !important;
//     transition: all 0.4s ease !important;
// }
.axa:hover,
.active-link {
    color: #6721ff;
}
.axa:hover::after,
.active-link::after {
    width: 100%;
}
